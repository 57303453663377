<template>
  <div class="shop-select">
    <el-select
      v-model="syncModel"
      value-key="id"
      multiple
      clearable
      collapse-tags
      placeholder="請選擇店家"
      no-data-text="暫無數據"
      @change="$emit('change')"
    >
      <el-option
        v-for="item in shopList"
        :key="item.id"
        :label="`${item.name} (${item.id.slice(-5)})`"
        :value="item"
      />
    </el-select>
  </div>
</template>

<script>
import { GetShops, GetShopsCount } from '@/api/shop'
export default {
  name: 'ShopMultiSelect',
  props: ['model', 'unitId'],

  data: () => ({
    shopList: [],
  }),

  computed: {
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  async mounted () {
    await this.getAllShops()
  },

  methods: {
    async getAllShops () {
      try {
        const count = await GetShopsCount({})
        let start = 0
        const limit = 5

        this.shopList = await GetShops({ start, limit })
        console.log(await GetShops({ start, limit }))

        while (this.shopList.length < count) {
          start += 5
          this.shopList.push(...await GetShops({ start, limit }))
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
